<template>
  <div>
    <i class="openDrawerButton el-icon-arrow-left" @click="drawer = true"></i>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/mitarbeiter' }">
        <app-i18n code="entities.mitarbeiter.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{ mitarbeiterFullName }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-content-page" v-loading="showSpinner">
      <el-row :gutter="10">
        <el-col :span="22">
          <app-mitarbeiter-form
            :isEditing="isEditing"
            :record="record"
            :saveLoading="showSpinner"
            :isInactive="isInactive"
            :viewMode="viewMode"
            @cancel="doCancel"
            @submit="doSubmit"
            v-if="!findLoading"
            ref="form"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MitarbeiterForm from '@/modules/mitarbeiter/components/mitarbeiter-form';

import { MitarbeiterPermissions } from '@/modules/mitarbeiter/mitarbeiter-permissions';
import { routerAsync } from '@/app-module';
export default {
  name: 'app-mitarbeiter-form-view-page',
  props: ['id'],
  components: {
    [MitarbeiterForm.name]: MitarbeiterForm,
  },
  data() {
    return {
      isSaving: false,
      drawer: false,
    };
  },
  async created() {
    await this.doFind(this.id);
    this.doGetAnstellungHistory();
    /*     this.doFetchNotiz(this.id); */
    this.doSetFilterAndFetchVertrag();
    this.doSetFilterAndFetchVertragsergaenzung();
    this.doSetFilterAndFetchVertragsaenderung();
    this.doSetFilterAndFetchBeendigung();
    await this.$store.dispatch('mitarbeiter/form/doSetViewMode', true);
  },
  computed: {
    ...mapGetters({
      record: 'mitarbeiter/form/record',
      findLoading: 'mitarbeiter/form/findLoading',
      saveLoading: 'mitarbeiter/form/saveLoading',
      currentUser: 'auth/currentUser',
      showSpinner: 'mitarbeiter/form/showSpinner',
      filter: 'mitarbeiter/list/filter',
      isInactive: 'mitarbeiter/form/isInactive',
      viewMode: 'mitarbeiter/form/viewMode',
      hasPermissionToCreateBeendigung: 'beendigung/hasPermissionToCreate',
      hasPermissionToCreateVertrag: 'vertrag/hasPermissionToCreate',
      hasPermissionToCreateBestaetigung: 'bestaetigung/hasPermissionToCreate',
    }),
    isEditing() {
      return !!this.id;
    },
    mitarbeiterFullName() {
      return this.record ? this.record.vollname : '';
    },
    hasPermissionToSendPayrollRegistration() {
      return new MitarbeiterPermissions(this.currentUser).edit;
    },
    hasPermissionToAbmelden() {
      return new MitarbeiterPermissions(this.currentUser).abmelden;
    },
    filter() {
      return {
        filter: {
          id: this.id,
        },
      };
    },
    isInactive() {
      return this.record.status === 'inactive';
    },
  },
  methods: {
    ...mapActions({
      doFind: 'mitarbeiter/form/doFind',
      doResetVertrag: 'vertrag/doReset',
      doResetBeendigung: 'beendigung/doReset',
      doFetchHistory: 'mitarbeiter/history/doFetch',
      doFetchVertrag: 'vertrag/doFetch',
      doFetchVertragsergaenzung: 'vertragsergaenzung/doFetch',
      doFetchVertragsaenderung: 'vertragsaenderung/doFetch',
      doFetchBeendigung: 'beendigung/doFetch',
    }),
    doCancel() {
      routerAsync().push('/mitarbeiter');
    },
    async doGetAnstellungHistory() {
      return this.doFetchHistory(this.filter);
    },
    async doSetFilterAndFetchVertrag() {
      return this.doFetchVertrag(this.filter);
    },
    async doSetFilterAndFetchVertragsergaenzung() {
      return this.doFetchVertragsergaenzung(this.filter);
    },
    async doSetFilterAndFetchVertragsaenderung() {
      return this.doFetchVertragsaenderung(this.filter);
    },
    async doSetFilterAndFetchBeendigung() {
      return this.doFetchBeendigung(this.filter);
    },
  },
};
</script>
<style scoped>
.openDrawerButton {
  float: right;
}
</style>
